import { useEffect, useState } from 'react'
import { EventTypes, IDataLayerParams, IDataLayer } from './typesUseDataLayer'

/**
 *  Data Layer Event
 *  Saiba Mais: https://interwiki.bi.local/bin/view/MKT/Growth%20Acquisition%20Ops/Tagueamento%20Web/Padr%C3%A3o%20de%20tagueamento%20Web/?srid=A6P7zyQd#HBody
 */

const returnDataLayer = (data: IDataLayerParams, dataLayerEvent: EventTypes) => {
  const { section, section_name, element_action, element_name, element_previous, step, redirect_url, element_search, value }: IDataLayerParams = data

  const dataLayer: IDataLayer = {
    event: dataLayerEvent,
    params: {
      section: section,
      section_name: section_name || 'null',
      element_action: element_action,
      element_name: element_name,
      element_previous: element_previous || 'null',
      c_page: window.location.href,
      step: step || 'null',
      redirect_url: redirect_url || 'null',
      element_search: element_search || 'null',
      value: value || 'null',
    },
  }

  return dataLayer
}

export const addEventToWindowDataLayer = (dataLayer: IDataLayerParams, event: EventTypes = "ga_event_body") => {
  const dataLayerEvent = returnDataLayer(dataLayer, event)

  if (window && window.dataLayer) {
    window.dataLayer.push(dataLayerEvent)
  }

  return window.dataLayer
}

export type DataLayerFunction = (dataLayer: IDataLayerParams) => Record<string, any>
const useDataLayer = (event?: EventTypes) => {
  const [ dataLayerEvent, setDataLayerEvent ] = useState<EventTypes>('ga_event_body')

  useEffect(() => {
    event && setDataLayerEvent(event)
  }, [ dataLayerEvent ])

  const sendDatalayerEvent = (dataLayer: IDataLayerParams) => {
    addEventToWindowDataLayer(dataLayer, dataLayerEvent)
  }

  return [ sendDatalayerEvent ]
}

export default useDataLayer
